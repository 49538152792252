<script>
/* eslint-disable */
import {VueDraggableNext} from 'vue-draggable-next';

import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import Layout from '../../layouts/main';

import {upcomingTasks, progressTasks, completedTasks} from './data-kanaban';

/**
 * Kanban-board component
 */
export default {
  page: {
    title: 'Kanban Board',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    draggable: VueDraggableNext,
    Layout,
    PageHeader,
  },
  data() {
    return {
      enabled: true,
      list: [
        {name: 'John', id: 1},
        {name: 'Joao', id: 2},
        {name: 'Jean', id: 3},
        {name: 'Gerard', id: 4},
      ],
      dragging: false,
      upcomingTasks,
      progressTasks,
      completedTasks,
      title: 'Kanban Board',
      items: [
        {
          text: 'Tasks',
          href: '/',
        },
        {
          text: 'Kanban Board',
          active: true,
        },
      ],
      items1: Array(5).fill({}).map((_, index) => ({title: `Item ${index + 1}`})),
      items2: [],
    };
  },
  methods: {
    log(event) {
      console.log(event);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div class="drag-container">
      <div class="row drag-list">
        <div class="col-lg-4 drag-column">
          <div class="card">
            <div class="card-body">
              <!-- dropdown -->
              <b-dropdown
                class="float-end"
                menu-class="dropdown-menu-end"
                right
                toggle-class="p-0"
                variant="white"
              >
                <template #button-content>
                  <i class="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                </template>
                <b-dropdown-item href="javascript: void(0);"
                >Edit
                </b-dropdown-item
                >
                <b-dropdown-item href="javascript: void(0);"
                >Delete
                </b-dropdown-item
                >
              </b-dropdown>
              <span class="drag-column-header">
                <h4 class="card-title mb-4 pb-1">Upcoming</h4>
              </span>

              <draggable
                v-model="items1"
                class="drop-zone"
                transition="100"
              >
                <template v-slot:item="{ item }">
                  <div class="draggable-item">
                    {{ item.title }}
                  </div>
                </template>
              </draggable>

              <draggable v-model="upcomingTasks" class="list-group">
                <div
                  v-for="task in upcomingTasks"
                  :key="task.id"
                  class="card task-box"
                >
                  <div class="card-body">
                    <div class="float-end ml-2">
                      <span
                        :class="{
                          'badge-soft-secondary': `${task.task}` === 'Waiting',
                          'badge-soft-success': `${task.task}` === 'Complete',
                          'badge-soft-primary': `${task.task}` === 'Approved',
                          'badge-soft-warning': `${task.task}` === 'Pending',
                        }"
                        class="badge rounded-pill font-size-12"
                      >{{ task.task }}</span
                      >
                    </div>
                    <div>
                      <h5 class="font-size-15">
                        <a class="text-dark" href="javascript: void(0);">{{
                            task.title
                          }}</a>
                      </h5>
                    </div>
                    <p class="text-muted mb-4">{{ task.date }}</p>
                    <div class="avatar-group float-start">
                      <div class="avatar-group-item">
                        <a class="d-inline-block" href="javascript: void(0);">
                          <img
                            :src="`${task.user[0]}`"
                            alt
                            class="rounded-circle avatar-xs"
                          />
                        </a>
                      </div>
                      <div v-if="task.user[1]" class="avatar-group-item">
                        <a class="d-inline-block" href="javascript: void(0);">
                          <img
                            :src="`${task.user[1]}`"
                            alt
                            class="rounded-circle avatar-xs"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="text-end">
                      <h5 class="font-size-15 mb-1">$ {{ task.budget }}</h5>
                      <p class="mb-0 text-muted">Budget</p>
                    </div>
                  </div>
                </div>
              </draggable>
              <div class="text-center d-grid">
                <a class="btn btn-primary mt-3" href="javascript: void(0);">
                  <i class="mdi mdi-plus me-1"></i> Add New
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- end col-->

        <div class="col-lg-4 drag-column">
          <div class="card">
            <div class="card-body">
              <!-- dropdown -->
              <b-dropdown
                class="float-end"
                menu-class="dropdown-menu-end"
                right
                toggle-class="p-0"
                variant="white"
              >
                <template #button-content>
                  <i class="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                </template>
                <b-dropdown-item href="javascript: void(0);"
                >Edit
                </b-dropdown-item
                >
                <b-dropdown-item href="javascript: void(0);"
                >Delete
                </b-dropdown-item
                >
              </b-dropdown>
              <span class="drag-column-header">
                <h4 class="card-title mb-4 pb-1">In Progress</h4>
              </span>
              <div class="flex m-10">
                <!-- <draggable class="dragArea list-group w-full" :list="list" @change="log">
                  <div
                    class="list-group-item bg-gray-300 m-1 p-3 rounded-md text-center"
                    v-for="element in list"
                    :key="element.name"
                  >
                    {{ element.name }}
                  </div>
                </draggable> -->
              </div>
              <draggable v-model="progressTasks" class="list-group">

                <div
                  v-for="task in progressTasks"
                  :key="task.id"
                  class="card task-box"
                >
                  <div class="card-body">
                    <div class="float-end ml-2">
                      <span
                        :class="{
                          'badge-soft-secondary': `${task.task}` === 'Waiting',
                          'badge-soft-success': `${task.task}` === 'Complete',
                          'badge-soft-primary': `${task.task}` === 'Approved',
                          'badge-soft-warning': `${task.task}` === 'Pending',
                        }"
                        class="badge rounded-pill font-size-12"
                      >{{ task.task }}</span
                      >
                    </div>
                    <div>
                      <h5 class="font-size-15">
                        <a class="text-dark" href="javascript: void(0);">{{
                            task.title
                          }}</a>
                      </h5>
                    </div>
                    <p class="text-muted mb-4">{{ task.date }}</p>
                    <div class="avatar-group float-start">
                      <div class="avatar-group-item">
                        <a class="d-inline-block" href="javascript: void(0);">
                          <img
                            :src="`${task.user[0]}`"
                            alt
                            class="rounded-circle avatar-xs"
                          />
                        </a>
                      </div>
                      <div v-if="task.user[1]" class="avatar-group-item">
                        <a class="d-inline-block" href="javascript: void(0);">
                          <img
                            :src="`${task.user[1]}`"
                            alt
                            class="rounded-circle avatar-xs"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="text-end">
                      <h5 class="font-size-15 mb-1">$ {{ task.budget }}</h5>
                      <p class="mb-0 text-muted">Budget</p>
                    </div>
                  </div>
                </div>
              </draggable>
              <div class="text-center d-grid">
                <a class="btn btn-primary mt-3" href="javascript: void(0);">
                  <i class="mdi mdi-plus me-1"></i> Add New
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- end col-->

        <div class="col-lg-4 drag-column">
          <div class="card">
            <div class="card-body">
              <!-- dropdown -->
              <b-dropdown
                class="float-end"
                menu-class="dropdown-menu-end"
                right
                toggle-class="p-0"
                variant="white"
              >
                <template #button-content>
                  <i class="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                </template>
                <b-dropdown-item href="javascript: void(0);"
                >Edit
                </b-dropdown-item
                >
                <b-dropdown-item href="javascript: void(0);"
                >Delete
                </b-dropdown-item
                >
              </b-dropdown>
              <span class="drag-column-header">
                <h4 class="card-title mb-4 pb-1">Completed</h4>
              </span>
              <draggable
                v-model="completedTasks"

                class="list-group"
              >
                <div
                  v-for="task in completedTasks"
                  :key="task.id"
                  class="card task-box"
                >
                  <div class="card-body">
                    <div class="float-end ml-2">
                      <span
                        :class="{
                          'badge-soft-secondary': `${task.task}` === 'Waiting',
                          'badge-soft-success': `${task.task}` === 'Complete',
                          'badge-soft-primary': `${task.task}` === 'Approved',
                          'badge-soft-warning': `${task.task}` === 'Pending',
                        }"
                        class="badge rounded-pill font-size-12"
                      >{{ task.task }}</span
                      >
                    </div>
                    <div>
                      <h5 class="font-size-15">
                        <a class="text-dark" href="javascript: void(0);">{{
                            task.title
                          }}</a>
                      </h5>
                    </div>
                    <p class="text-muted mb-4">{{ task.date }}</p>
                    <div class="avatar-group float-start">
                      <div class="avatar-group-item">
                        <a class="d-inline-block" href="javascript: void(0);">
                          <img
                            :src="`${task.user[0]}`"
                            alt
                            class="rounded-circle avatar-xs"
                          />
                        </a>
                      </div>
                      <div v-if="task.user[1]" class="avatar-group-item">
                        <a class="d-inline-block" href="javascript: void(0);">
                          <img
                            :src="`${task.user[1]}`"
                            alt
                            class="rounded-circle avatar-xs"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="text-end">
                      <h5 class="font-size-15 mb-1">$ {{ task.budget }}</h5>
                      <p class="mb-0 text-muted">Budget</p>
                    </div>
                  </div>
                </div>
              </draggable>
              <div class="text-center d-grid">
                <a class="btn btn-primary mt-3" href="javascript: void(0);">
                  <i class="mdi mdi-plus me-1"></i> Add New
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- end col-->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
